import React, { Component } from "react";
import {
  deleteMenu,
  deleteMenuItem,
  deleteProductCategory,
  getAllProductCategory
} from "../../../functions/postFunctions";

import AddProductCategory from "../../../components/AddProductCategory/AddProductCategory";
import { CustomButton } from "../../../components/Buttons/Button";
import EditProductCategory from "../../../components/EditProductCategory/EditProductCategory";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import SortableList from "../../../components/SortableGrid/SortableList";

class ProductsCategory extends Component {
  state = {
    theads: ["Naziv", "Pozicija", "Akcije"],
    menus: [],
    positions: [
      {
        value: 0,
        label: "Glavni Meni"
      },
      {
        value: 1,
        label: "Meni na vrhu"
      },
      {
        value: 2,
        label: "Lijevi meni"
      },
      {
        value: 3,
        label: "Desni Meni"
      },
      {
        value: 4,
        label: "Meni u podnožju - Lijevo"
      },
      {
        value: 5,
        label: "Meni u podnožju - Desno"
      }
    ],
    allCategories: [],
    items: [],
    showAddMenuItem: false,
    showEditMenuItem: false,
    itemForEdit: null,
    menu: null,
    currentCategory: [],
    dropdownCategories: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Kategorije proizvoda");

    getAllProductCategory().then(res => {
      let dropdownCategories = res.categories.map(e => ({
        value: e.id,
        label: e.name
      }));
      dropdownCategories.unshift({ value: 0, label: "Sve kategorije" });
      if (res.success) {
        this.setState({
          allCategories: res.categories,
          items: [res.categories[0]],
          currentCategory: { value: 0, label: "Sve kategorije" },
          dropdownCategories: dropdownCategories
        });
      }
    });
  }

  addToList = items => {
    this.setState({ items }, () => this.hideModal());
  };

  handleDeletePage = id => {
    deleteMenu(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ menus: res.menus });
      }
    });
  };

  findAllMenus = array => {
    let menus = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  showModal = (name, id) => {
    let item;
    if (id) {
      const items = this.findAllMenus(this.state.items);
      item = items.find(e => e.id === id);
    }
    this.setState({ [name]: true, itemForEdit: item });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentCategory !== this.state.currentCategory) {
      let items;
      if (this.state.currentCategory.value === 0) {
        items = this.state.allCategories.map(e => ({
          name: e.name,
          id: e.id,
          level: e.level,
          order: e.order,
          children: []
        }));
        this.setState({ items: [] }, () => this.setState({ items }));
      } else {
        items = this.state.allCategories.find(
          e => e.id === this.state.currentCategory.value
        );
        this.setState({ items: [] }, () => this.setState({ items: [items] }));
      }
    }
  }

  hideModal = () => {
    this.setState({
      showAddMenuItem: false,
      itemForEdit: null
    });
  };

  handleDeleteMenuItem = id => {
    this.setState({ items: [] });
    deleteMenuItem(id, this.props.match.params.id, this.props.token).then(
      res => {
        if (res.success) {
          this.setState({ items: res.items });
        }
      }
    );
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  deleteCategory = id => {
    this.setState({ items: [] });
    deleteProductCategory(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ items: res.categories });
      }
    });
  };

  listElement = el => {
    return (
      <SortableList
        items={el}
        key={this.props.key}
        listElement={this.listElement}
        token={this.props.token}
        deleteCategory={this.deleteCategory}
        showModal={this.showModal}
        categories
      />
    );
  };

  render() {
    const {
      items,
      currentCategory,
      dropdownCategories,
      allCategories
    } = this.state;

    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdown
                data={dropdownCategories}
                placeholder="Kategorija"
                name="currentCategory"
                handleChange={this.handleInput}
                value={currentCategory}
              />
            </div>
            <div className="col-md-3">
              <CustomButton onClick={() => this.showModal("showAddMenuItem")}>
                Dodaj kategoriju
              </CustomButton>
            </div>
          </div>
          <AddProductCategory
            visible={this.state.showAddMenuItem}
            hideModal={this.hideModal}
            token={this.props.token}
            addToList={this.addToList}
            menus={allCategories}
            id={this.props.match.params.id}
          />
          <EditProductCategory
            visible={this.state.showEditMenuItem}
            hideModal={this.hideModal}
            token={this.props.token}
            addToList={this.addToList}
            menus={allCategories}
            id={this.props.match.params.id}
            itemForEdit={this.state.itemForEdit}
            menuName={this.state.menu && this.state.menu.name}
          />
        </div>

        <div className="row my-20">
          <div className="col-12">
            <div className="menuList">
              {items.length > 0 ? (
                this.listElement(items)
              ) : (
                <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                  <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                    Učitavanje
                  </h3>
                  <div className="optimization loader d-flex justify-content-center align-items-center">
                    <div className="fancy-spinner">
                      <div className="ring" />
                      <div className="ring" />
                      <div className="dot" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsCategory;
