import React from "react";
import Select, { components } from "react-select";

const SearchableDropdownMultiple = ({
  data,
  placeholder,
  name,
  handleChange,
  value,
  index
}) => {
  const Menu = props => {
    return (
      <components.Menu style={{ fontSize: "14px" }} {...props}>
        {props.children}
      </components.Menu>
    );
  };
  return !value ? (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={data}
      noDataRenderer={"Nema rezultata"}
      onChange={e => handleChange(name, e.value, index)}
      placeholder={placeholder}
      menuPlacement="auto"
      components={{ Menu }}
      // menuIsOpen
      styles={{
        groupHeading: base => ({
          ...base,
          fontSize: "14px"
        })
      }}
    />
  ) : (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={data}
      noDataRenderer={"Nema rezultata"}
      onChange={e => handleChange(name, e, true)}
      placeholder={placeholder}
      value={value}
      menuPlacement="auto"
      styles={{
        groupHeading: base => ({
          ...base,
          fontSize: "14px"
        })
      }}
      // menuIsOpen
    />
  );
};

export default SearchableDropdownMultiple;
