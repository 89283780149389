import React from "react";

export const CustomInput = ({
  value,
  label,
  placeholder,
  handleChange,
  type,
  name,
  index,
  multiple,
  readOnly,
  className,
  counter,
  realTime,
  fast
}) => {
  let count = 0;
  const handleCounter = val => {
    if (
      name !== "slug" &&
      name !== "username" &&
      name !== "password" &&
      counter
    ) {
      document.getElementById(`${name}-${index}`).innerHTML = val;
    }
  };
  if (type !== "file") {
    if (!fast) {
      return (
        <div
          className={`inputWrapper ${
            value !== "" ? "hasText" : ""
          } ${className || ""}`}
        >
          <input
            placeholder={placeholder || ""}
            defaultValue={!realTime ? value : ""}
            onBlur={e => handleChange(name, e.target.value, index)}
            onChange={e => handleCounter(e.target.value.length)}
            type={type || "text"}
            multiple={multiple}
            readOnly={readOnly}
          />
          <label>{label}</label>
          {name !== "slug" &&
          name !== "username" &&
          name !== "password" &&
          counter ? (
            <span className="count f-s-10 px-5">
              Broj karaktera: <b id={`${name}-${index}`}>{count}</b>
            </span>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return (
        <div
          className={`inputWrapper ${
            value !== "" ? "hasText" : ""
          } ${className || ""}`}
        >
          <input
            placeholder={placeholder || ""}
            value={value}
            onChange={e => handleChange(name, e.target.value, index)}
            type={type || "text"}
            multiple={multiple}
            readOnly={readOnly}
          />
          <label>{label}</label>
        </div>
      );
    }
  } else {
    return (
      <div className={`inputWrapper ${value ? "hasText" : ""} file`}>
        <input
          placeholder={placeholder || ""}
          defaultValue={value}
          onChange={e => handleChange(e.target.files)}
          type={type || "text"}
          multiple={multiple}
          name={name}
          id={name}
        />
        <label
          htmlFor={name}
          className="btn btn-primary f-s-16 text-color-white transition-1 px-20 py-5 br-r-5 d-flex align-items-center justify-content-center lh "
        >
          {label}
        </label>
      </div>
    );
  }
};

export const CustomLoginInput = ({
  value,
  label,
  placeholder,
  handleChange,
  type,
  name,
  index,
  multiple,
  readOnly,
  className
}) => {
  return (
    <div className={`inputWrapper ${value ? "hasText" : ""} ${className}`}>
      <input
        placeholder={placeholder || ""}
        value={value}
        onChange={e => handleChange(name, e.target.value, index)}
        type={type || "text"}
        multiple={multiple}
        readOnly={readOnly}
      />
      <label>{label}</label>
    </div>
  );
};

export const CustomTextArea = ({
  value,
  label,
  placeholder,
  handleChange,
  name,
  className,
  index
}) => {
  const handleCounter = val => {
    document.getElementById(`${name}-${index}`).innerHTML = val;
  };

  return (
    <div
      className={`inputWrapper ${value ? "hasText" : ""} ${
        className ? className : ""
      }`}
    >
      <textarea
        placeholder={placeholder || ""}
        defaultValue={value}
        onChange={e => handleCounter(e.target.value.length)}
        onBlur={e => handleChange(name, e.target.value, index)}
      />
      <label>{label}</label>
      <span className="count f-s-10 px-5">
        Broj karaktera: <b id={`${name}-${index}`}>{0}</b>
      </span>
    </div>
  );
};
