import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

import Logo from "./Logo/Logo";

import { Home } from "./Icons/Icons";

import { moveIndicator } from "../../functions/functions";
import {
  PlayerIcon,
  PagesIcon,
  SettingsIcon,
  FootbalMatchIcon,
  TeamsIcon,
  TrophyIcon,
  TablesIcon,
  StaffIcon,
  VideosIcon,
  MenuIcon,
  ProductIcon,
  OrdersIcon,
  DiscountsIcon
} from "../icons/icons";

class Navbar extends Component {
  state = {
    playersDropdown: false,
    pagesDropdown: false,
    productDropdown: false
  };
  linkHover = () => {
    const elements = document.getElementById("nav").querySelectorAll("a");

    for (let i = 0; i < elements.length; i++) {
      elements[i].onmouseover = function(e) {
        elements[i].setAttribute("data-hover", "hoverActive");
      };
      elements[i].onmouseout = function() {
        elements[i].setAttribute("data-hover", "hoverLeft");
      };
    }
  };
  componentDidMount() {
    this.linkHover();
  }

  toggleDropdown = (e, dropdown) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      [dropdown]: !prevState[dropdown]
    }));
  };

  closeDropdown = () => {
    this.setState({
      playersDropdown: false,
      pagesDropdown: false
    });
  };

  render() {
    return (
      <>
        <nav id="nav">
          <div className="logoContainer p-r d-flex justify-content-center align-items-center">
            <Link to="/" className="d-flex align-items-center">
              <Logo className="center-r-v pl-30" />
            </Link>
          </div>
          <div className="navContainer">
            <ul className="f-s-12" onClick={this.closeDropdown}>
              <li>
                <NavLink exact={true} to="/meniji" className="p-r">
                  <span className="icon d-inline-block mr-10">
                    <MenuIcon className="fill" />
                  </span>
                  <span className="title d-inline-block">Meniji</span>
                </NavLink>
              </li>

              <li>
                {/*<NavLink exact={true} to="/" className=" p-r">*/}
                {/*  <span className="icon d-inline-block mr-10">*/}
                {/*    <Home className="fill" />*/}
                {/*  </span>*/}
                {/*  <span className="title d-inline-block">Početna</span>*/}
                {/*</NavLink>*/}
              </li>
              <li className="subMenu">
                <a
                  className="subMenuToggle p-r"
                  onClick={e => this.toggleDropdown(e, "pagesDropdown")}
                >
                  <span className="icon d-inline-block mr-10">
                    <PagesIcon className="fill" />
                  </span>
                  <span className="title d-inline-block">Stranice</span>
                </a>
                <div
                  style={{ height: this.state.pagesDropdown ? "100px" : 0 }}
                  className="subMenuDropdown"
                  id="playersDropdown"
                >
                  <NavLink
                    exact={true}
                    to="/"
                    className="p-r"
                    onClick={e => e.stopPropagation()}
                  >
                    <span className="pl-15 title d-inline-block">Stranice</span>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to="/kategorije-stranice"
                    className="p-r"
                    onClick={e => e.stopPropagation()}
                  >
                    <span className="pl-15 title d-inline-block">
                      Kategorije stranica
                    </span>
                  </NavLink>
                </div>
              </li>
              <li className="subMenu">
                <a
                  className="subMenuToggle p-r"
                  onClick={e => this.toggleDropdown(e, "productDropdown")}
                >
                  <span className="icon d-inline-block mr-10">
                    <ProductIcon className="fill" />
                  </span>
                  <span className="title d-inline-block">Proizvodi</span>
                </a>
                <div
                  style={{ height: this.state.productDropdown ? "100px" : 0 }}
                  className="subMenuDropdown"
                  id="playersDropdown"
                >
                  <NavLink
                    exact={true}
                    to="/proizvodi/1"
                    className="p-r"
                    onClick={e => e.stopPropagation()}
                  >
                    <span className="pl-15 title d-inline-block">
                      Proizvodi
                    </span>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to="/kategorije-proizvodi"
                    className="p-r"
                    onClick={e => e.stopPropagation()}
                  >
                    <span className="pl-15 title d-inline-block">
                      Kategorije proizvoda
                    </span>
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink exact={true} to="/porudzbine" className="p-r">
                  <span className="icon d-inline-block mr-10">
                    <OrdersIcon className="fill" />
                  </span>
                  <span className="title d-inline-block">Porudžbine</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact={true} to="/popusti" className="p-r">
                  <span className="icon d-inline-block mr-10">
                    <DiscountsIcon className="fill" />
                  </span>
                  <span className="title d-inline-block">Popusti</span>
                </NavLink>
              </li>
              <li>
                {/*<NavLink exact={true} to="/podesavanja" className=" p-r">*/}
                {/*  <span className="icon d-inline-block mr-10">*/}
                {/*    <SettingsIcon className="fill" />*/}
                {/*  </span>*/}
                {/*  <span className="title d-inline-block">Podešavanja</span>*/}
                {/*</NavLink>*/}
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
