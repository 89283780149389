import React, { Component } from "react";
import {
  deleteOrder,
  deleteProduct,
  getAllOrders,
  getAllProducts,
  incompleteOrder
} from "../../functions/postFunctions";
import { LinkButton, CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import { dateLocalisation } from "../../functions/functions";
import Pagination from "react-js-pagination";

class OrdersPage extends Component {
  state = {
    theads: [
      "RB",
      "Detalji naručioca",
      "Adresa",
      "Vrsta plaćanja",
      "Poručeno",
      "Total",
      "Akcije"
    ],
    orders: [],
    removeLoadMore: false,
    page: 1,
    activePage: 1,
    categoryList: [],
    currentCategory: null,
    max: 0,
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Porudžbine");

    getAllOrders().then(res => {
      if (res.success) {
        this.setState({ orders: res.orders });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
  }

  handlePageChange = (pageNumber) => {
    this.props.history.push(`${pageNumber}`);
  };

  handleDeletePage = id => {
    const { orders } = this.state;
    const newOrders = orders.filter(e => e.id !== id);
    deleteOrder(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ orders: newOrders });
      }
    });
  };
  handleSetIncomplete = (id, status) => {
    let { orders } = this.state;
    incompleteOrder(id, this.props.token, status).then(res => {
      if (res.success) {
        orders.find(i => i.id === id).is_incomplete = status
        this.setState({ orders: orders });
      }
    });
  };

  loadNews = () => {
    getAllOrders(20, 0).then(res => {
      if (res.success) {
        this.setState({
          orders: res.orders,
          page: 1,
          removeLoadMore: res.orders.length < 20,
          max: res.orders.length > 0 ? res.total : 0,
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page } = this.state;
    getAllOrders(20, page * 20).then(res => {
      if (res.success) {
        this.setState(({ orders, page }) => ({
          orders: [...orders, ...res.orders],
          page: page + 1,
          removeLoadMore: res.orders.length < 20,
          max: res.orders.length > 0 ? res.total : 0,
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { theads, orders, categoryList, currentCategory } = this.state;
    return (
      <div>
        {/*<div className="pageTop mb-40">*/}
        {/*  /!*<div className="row">*!/*/}
        {/*  /!*  <div className="col-md-3">*!/*/}
        {/*  /!*    <SearchableDropdown*!/*/}
        {/*  /!*      data={categoryList}*!/*/}
        {/*  /!*      placeholder="Kategorija"*!/*/}
        {/*  /!*      name="currentCategory"*!/*/}
        {/*  /!*      handleChange={this.handleInput}*!/*/}
        {/*  /!*      value={currentCategory}*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*  <div className="col-md-9">*!/*/}
        {/*  /!*    <LinkButton href="dodaj-proizvod">Dodaj proizvod</LinkButton>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
        <Table theads={theads}>
          {orders.map((e, index) => {
            return (
              <tr key={index} style={{backgroundColor: e && e.is_incomplete && e.is_incomplete === 1 ? '#ff000047' : null}}>
                <td className="td px-10">
                  <span className="f-s-16">
                    #{e.id}
                  </span>
                </td>
                <td className="td px-10">
                  <div className="wrapper d-flex flex-column">
                    <span className="f-s-16">{e.name}</span>
                    <span className="f-s-16">{e.email}</span>
                    <span className="f-s-16">{e.phone}</span>
                  </div>
                </td>
                <td className="td px-10">
                  <div className="wrapper d-flex flex-column">
                    <span className="f-s-16">{e.address}</span>
                    <span className="f-s-16">{e.city}</span>
                  </div>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.payment_type}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">
                    {dateLocalisation(e.created_at)}
                  </span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.order_total} &euro;</span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <LinkButton href={`porudzbine/${e.id}`} className="mr-10">
                    Detalji
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red mr-10"
                  >
                    Izbriši
                  </CustomButton>
                  <CustomButton
                    onClick={() => this.handleSetIncomplete(e.id, e.is_incomplete && e.is_incomplete === 1 ? 0 : 1)}
                    style={{backgroundColor: 'rgb(255 124 0)'}}
                    className="mt-10"
                  >
                    Neostvareno
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
        {!this.state.removeLoadMore ? (
          <div className="wrapper mt-40 d-flex justify-content-center">
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        //   <div className='wrapper mt-40 d-flex justify-content-center'>
        //   <Pagination
        //     hideDisabled
        //     activePage={this.state.activePage}
        //     itemsCountPerPage={20}
        //     totalItemsCount={this.state.max}
        //     pageRangeDisplayed={5}
        //     onChange={this.handlePageChange}
        //   />
        // </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default OrdersPage;
